/** @jsx jsx */

import { Fragment } from "react"
import { jsx, Flex } from "theme-ui"
import PropTypes from "prop-types"
import { BiChevronRight, BiChevronLeft } from "react-icons/bi"

import { Link } from "./Link"
import { MediaMatcher } from "./MediaMatcher"

const PaginationItem = ({ children, isSelected, to, ...props }) => (
  <li>
    <Link
      to={to}
      variant="pagination"
      sx={{
        ...(isSelected ? { bg: "oliveGreen", color: "white" } : {}),
      }}
      {...props}
    >
      {children}
    </Link>
  </li>
)
PaginationItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
}

const PaginationItems = ({
  numPages,
  selected,
  numVisibleSiblings,
  maxItems,
  pagePathPrefix,
}) => {
  const rangeArray = Array(numPages)
    .fill(0)
    .map((_, i) => i + 1)

  if (numPages <= maxItems) {
    return (
      <Fragment>
        {rangeArray.map(page => (
          <PaginationItem
            key={page}
            isSelected={selected === page}
            to={`${pagePathPrefix}${page}`}
          >
            {page}
          </PaginationItem>
        ))}
      </Fragment>
    )
  } else {
    const firstPageDisplayedIndex = Math.max(
      selected - numVisibleSiblings - 1,
      0
    )
    const lastPageDisplayedIndex = Math.min(
      selected + numVisibleSiblings,
      numPages
    )

    const showPrevArrowAndEllipses = selected - numVisibleSiblings > 2
    const showNextArrowAndEllipses =
      selected + numVisibleSiblings < numPages - 1

    // If we only display one sibling page, and are already showing the arrows
    // displaying the sibling pages is redundant
    const isSiblingDisplayRedundant =
      showPrevArrowAndEllipses &&
      showNextArrowAndEllipses &&
      numVisibleSiblings === 1
    const pageItemsToDisplay = isSiblingDisplayRedundant
      ? [selected]
      : rangeArray.slice(firstPageDisplayedIndex, lastPageDisplayedIndex)
    const showFirst = selected - numVisibleSiblings > 1
    const showLast = selected + numVisibleSiblings < numPages

    return (
      <Fragment>
        {showPrevArrowAndEllipses ? (
          <PaginationItem
            data-testid="prev-arrow"
            to={`${pagePathPrefix}${selected - 1}`}
          >
            <BiChevronLeft color="oliveGreen" size={32} />
          </PaginationItem>
        ) : null}
        {showFirst ? (
          <PaginationItem isSelected={selected === 1} to={`${pagePathPrefix}1`}>
            1
          </PaginationItem>
        ) : null}
        {showPrevArrowAndEllipses ? (
          <PaginationItem data-testid="prev-ellipses">...</PaginationItem>
        ) : null}
        {pageItemsToDisplay.map(page => (
          <PaginationItem
            key={page}
            isSelected={selected === page}
            to={`${pagePathPrefix}${page}`}
          >
            {page}
          </PaginationItem>
        ))}
        {showNextArrowAndEllipses ? (
          <PaginationItem data-testid="next-ellipses">...</PaginationItem>
        ) : null}
        {showLast ? (
          <PaginationItem
            isSelected={selected === numPages}
            to={`${pagePathPrefix}${numPages}`}
          >
            {numPages}
          </PaginationItem>
        ) : null}
        {showNextArrowAndEllipses ? (
          <PaginationItem
            data-testid="next-arrow"
            to={`${pagePathPrefix}${selected + 1}`}
          >
            <BiChevronRight color="oliveGreen" size={32} />
          </PaginationItem>
        ) : null}
      </Fragment>
    )
  }
}
PaginationItems.propTypes = {
  numPages: PropTypes.number,
  selected: PropTypes.number,
  numVisibleSiblings: PropTypes.number,
  maxItems: PropTypes.number,
  pagePathPrefix: PropTypes.string,
}

export const Pagination = ({ selected, numPages, pagePathPrefix }) => {
  // Number of sibling pages to show each on the left and right
  const numVisibleSiblings = MediaMatcher.useMedia({
    mobilePortrait: 1,
    mobileLandscape: 2,
  })

  const maxItems = MediaMatcher.useMedia({
    mobilePortrait: 7,
    mobileLandscape: 11,
  })

  return (
    <Flex
      as="ul"
      sx={{
        justifyContent: "center",
        p: 0,
        my: 7,
        listStyle: "none",
      }}
    >
      <PaginationItems
        numPages={numPages}
        selected={selected}
        numVisibleSiblings={numVisibleSiblings}
        maxItems={maxItems}
        pagePathPrefix={pagePathPrefix}
      />
    </Flex>
  )
}
Pagination.propTypes = {
  selected: PropTypes.number,
  numPages: PropTypes.number,
  pagePathPrefix: PropTypes.string,
}
