import { chunk } from "./paginationUtils"

export const ARTICLES_PER_PAGE = 10
export const NUM_TOP_CATEGORIES = 10
export const SortBy = {
  NEWEST_TO_OLDEST: "NEWEST_TO_OLDEST",
  OLDEST_TO_NEWEST: "OLDEST_TO_NEWEST",
}
export const SortByToPathPrefix = {
  [SortBy.NEWEST_TO_OLDEST]: "/articles",
  [SortBy.OLDEST_TO_NEWEST]: "/articles/oldest",
}

const sortOrder = sortBy =>
  sortBy === SortBy.NEWEST_TO_OLDEST ? "DESC" : "ASC"

const articleIds = async (graphql, { sortBy }) => {
  const innerSortOrder = sortOrder(sortBy)

  const { data } = await graphql(`
   query ArticlesIndexQuery {
      allPrismicArticle(
        sort: {
          fields: [data___publication_date, first_publication_date]
          order: [${innerSortOrder}, ${innerSortOrder}]
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  `)

  return data.allPrismicArticle.edges
}

const articlesByTopCategories = async (
  graphql,
  { sortBy, numTopCategories }
) => {
  const innerSortOrder = sortOrder(sortBy)

  const { data } = await graphql(`
  query ArticlesIndexQuery {
      allPrismicArticle(
        sort: {
          fields: [data___publication_date, first_publication_date]
          order: [${innerSortOrder}, ${innerSortOrder}]
        }
      ) {
        group(field: data___tags___category___uid) {
          categoryUid: fieldValue
          totalCount
          edges {
            node {
              id
              data {
                cover_image {
                  alt
                  gatsbyImageData(
                    height: 120,
                    imgixParams: { fit: "crop" }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicArticle.group
    .sort((a, b) => b.totalCount - a.totalCount)
    .slice(0, numTopCategories)
}

const topCategories = async (graphql, articlesByTopCategories) => {
  const topCategoryUids = articlesByTopCategories.map(
    articleByCategory => articleByCategory.categoryUid
  )
  const topCategoryUidsString = topCategoryUids.map(uid => `"${uid}"`).join(",")

  const { data } = await graphql(`
    query TopCategoriesQuery {
      allPrismicCategory(
        filter: { uid: { in: [${topCategoryUidsString}] } }
      ) {
        edges {
          node {
            uid
            data {
              name
            }
          }
        }
      }
    }
  `)

  const randomArticleImage = articles => {
    const randomIndex = Math.floor(Math.random() * articles.length)
    return articles[randomIndex].node.data.cover_image
  }

  return data.allPrismicCategory.edges
    .sort((a, b) => {
      return (
        topCategoryUids.indexOf(a.node.uid) -
        topCategoryUids.indexOf(b.node.uid)
      )
    })
    .map((edge, i) => ({
      uid: edge.node.uid,
      name: edge.node.data.name,
      image: randomArticleImage(articlesByTopCategories[i].edges),
    }))
}

export const createHomePage = async (
  actions,
  graphql,
  { numTopCategories }
) => {
  const innerTopCategories = await topCategories(
    graphql,
    await articlesByTopCategories(graphql, {
      sortBy: SortBy.NEWEST_TO_OLDEST,
      numTopCategories,
    })
  )

  actions.createPage({
    path: "/",
    component: require.resolve(`../templates/HomePageTemplateWithQuery.jsx`),
    context: {
      topCategories: innerTopCategories,
    },
  })
}

export const createArticlesIndexPages = async (
  actions,
  graphql,
  { articlesPerPage, sortBy, byCategory, numTopCategories }
) => {
  const innerArticlesByTopCategories = await articlesByTopCategories(graphql, {
    sortBy,
    numTopCategories,
  })
  const innerTopCategories = await topCategories(
    graphql,
    innerArticlesByTopCategories
  )
  const pathPrefix = SortByToPathPrefix[sortBy]

  if (byCategory) {
    const articlePagesByTopCategories = innerArticlesByTopCategories.map(
      articlesByCategory => ({
        categoryUid: articlesByCategory.categoryUid,
        articlesPages: chunk(articlesByCategory.edges, articlesPerPage),
      })
    )

    articlePagesByTopCategories.forEach(articlesByCategory => {
      const numPages = articlesByCategory.articlesPages.length

      articlesByCategory.articlesPages.forEach((articles, i) => {
        actions.createPage({
          path: `${pathPrefix}/category/${articlesByCategory.categoryUid}/${
            i + 1
          }`,
          component: require.resolve(
            `../templates/ArticlesPageTemplateWithQuery.jsx`
          ),
          context: {
            page: i + 1,
            numPages,
            articleIds: articles.map(article => article.node.id),
            topCategories: innerTopCategories,
            sortOrder: sortOrder(sortBy),
          },
        })
      })
    })
  } else {
    const articlesPages = chunk(
      await articleIds(graphql, { sortBy }),
      articlesPerPage
    )

    articlesPages.map(async (articles, i) => {
      return actions.createPage({
        path: `${pathPrefix}/${i + 1}`,
        component: require.resolve(
          `../templates/ArticlesPageTemplateWithQuery.jsx`
        ),
        context: {
          page: i + 1,
          numPages: articlesPages.length,
          articleIds: articles.map(article => article.node.id),
          topCategories: innerTopCategories,
          sortOrder: sortOrder(sortBy),
        },
      })
    })
  }
}

export const createArticleDetailPages = async (actions, graphql) => {
  let data

  data = (
    await graphql(`
      query ArticlesDetailQuery {
        allPrismicArticle {
          edges {
            node {
              uid
              id
            }
          }
        }
      }
    `)
  ).data

  await Promise.all(
    data.allPrismicArticle.edges.map(async edge => {
      const { id, uid } = edge.node

      const { data: articleData } = await graphql(`
      query ArticleQuery {
        prismicArticle(id: { eq: "${id}" }) {
          data {
            tags {
              category {
                id
              }
            }
          }
        }
      }
    `)

      const currentArticleCategoryIds =
        articleData.prismicArticle.data.tags.map(tag => tag.category.id)
      actions.createPage({
        path: `/articles/${uid}`,
        component: require.resolve(
          `../templates/ArticlePageTemplateWithQuery.jsx`
        ),
        context: { id, currentArticleCategoryIds },
      })
    })
  )
}

export const createJournazineDetailPages = async (actions, graphql) => {
  const { data } = await graphql(`
    query JournazinesDetailQuery {
      allPrismicJournazine {
        edges {
          node {
            uid
            id
          }
        }
      }
    }
  `)
  await Promise.all(
    data.allPrismicJournazine.edges.map(async edge => {
      const { id, uid } = edge.node

      actions.createPage({
        path: `/journazines/${uid}`,
        component: require.resolve(
          `../templates/JournazinePageTemplateWithQuery.jsx`
        ),
        context: { id, uid },
      })
    })
  )
}

const extractProductCataloguePaths = (
  productCataloguePaths,
  catalogueItems
) => {
  if (catalogueItems) {
    catalogueItems.forEach(item => {
      // Manually do the check here instead of importing the isProduct
      // utility from eCommerce utils as that contains lots of other
      // util functions that use syntax not fully supported in ESM
      // (specifically, the optional chaining syntax a?.b breaks)
      if (item.type === "product") {
        productCataloguePaths.push(item.path)
      }

      if (item.children) {
        extractProductCataloguePaths(productCataloguePaths, item.children)
      }
    })
  }
}

export const createProductPages = async (actions, graphql) => {
  const { data } = await graphql(`
    query Products {
      crystallize {
        catalogue(path: "/") {
          children {
            type
            path
            shape {
              name
            }
            children {
              type
              path
              shape {
                name
              }
            }
          }
        }
      }
    }
  `)

  let productCataloguePaths = []
  extractProductCataloguePaths(
    productCataloguePaths,
    data.crystallize.catalogue.children
  )

  await Promise.all(
    productCataloguePaths.map(async path => {
      actions.createPage({
        path: `/products${path}`,
        component: require.resolve(
          `../templates/ProductPageTemplateWithQuery.jsx`
        ),
        context: { cataloguePath: path },
      })
    })
  )
}

export const createCustomPages = async (actions, graphql) => {
  const data = (
    await graphql(`
      query CustomPageQuery {
        allPrismicCustomPage {
          edges {
            node {
              uid
              id
            }
          }
        }
      }
    `)
  ).data

  await Promise.all(
    data.allPrismicCustomPage.edges.map(async edge => {
      const { id, uid } = edge.node

      actions.createPage({
        path: `/${uid}`,
        component: require.resolve(
          `../templates/CustomPageTemplateWithQuery.jsx`
        ),
        context: { id },
      })
    })
  )
}
