/** @jsx jsx */

import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { ArticlesPageTemplate } from "./ArticlesPageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query ArticlesPageQuery($articleIds: [String], $sortOrder: SortOrderEnum) {
    allPrismicArticle(
      filter: { id: { in: $articleIds } }
      sort: {
        fields: [data___publication_date, first_publication_date]
        order: [$sortOrder, $sortOrder]
      }
    ) {
      totalCount
      edges {
        node {
          ...ArticleCard
        }
      }
    }

    prismicArticlesPage {
      _previewable
      type
      data {
        title {
          text
        }
        masthead_background {
          gatsbyImageData(width: 1920, placeholder: BLURRED)
          thumbnails {
            mobile_portrait {
              gatsbyImageData(width: 420, placeholder: BLURRED)
            }
            mobile_landscape {
              gatsbyImageData(width: 900, placeholder: BLURRED)
            }
          }
        }
        meta_title
        meta_description
        language
        canonical_url {
          url
        }
        opengraph_image {
          url
        }
      }
    }
  }
`

export default withPrismicPreview(ArticlesPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
