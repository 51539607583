/** @jsx jsx */

import { jsx, Label, Grid } from "theme-ui"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import theme from "../../gatsby-plugin-theme-ui"
import { CategoryPropTypes } from "../../utils/propTypes"
import { SortByToPathPrefix } from "../../utils/pageUtils"
import { SortBy } from "../../utils/pageUtils"
import { SelectField } from "../SelectField"

export const ArticlesSortAndFilterControls = ({
  pagePathPrefix,
  topCategories,
}) => {
  const defaultSortValue = pagePathPrefix.includes("oldest")
    ? SortBy.OLDEST_TO_NEWEST
    : SortBy.NEWEST_TO_OLDEST

  const categoryPathRegex = new RegExp("category/(.*)/")
  const categoryInPath = pagePathPrefix.match(categoryPathRegex)
  const defaultCategoryValue = categoryInPath ? categoryInPath[1] : "all"

  return (
    <Grid
      columns={[1, 2, 4, 5]}
      sx={{
        ...theme.basePageStyles.contentContainer,
        mt: 4,
      }}
    >
      <div sx={{ mb: 3, gridColumnStart: [1, 1, 3, 4] }}>
        <Label htmlFor="sortBy" variant="labelWhite">
          Sort By
        </Label>
        <SelectField
          name="sortBy"
          options={[
            {
              value: SortBy.NEWEST_TO_OLDEST,
              label: "Newest to oldest",
            },
            {
              value: SortBy.OLDEST_TO_NEWEST,
              label: "Oldest to newest",
            },
          ]}
          defaultValue={defaultSortValue}
          onChange={e => navigate(`${SortByToPathPrefix[e.target.value]}/1`)}
          variant="selectWhite"
        />
      </div>
      <div sx={{ mb: 4, gridColumnStart: [1, 2, 4, 5] }}>
        <Label htmlFor="category" variant="labelWhite">
          Emergent themes
        </Label>
        <SelectField
          name="category"
          options={[
            {
              value: "all",
              label: "All",
            },
            ...topCategories.map(category => ({
              value: category.uid,
              label: category.name,
            })),
          ]}
          defaultValue={defaultCategoryValue}
          onChange={e =>
            e.target.value === "all"
              ? navigate(`${SortByToPathPrefix[defaultSortValue]}/1`)
              : navigate(
                  `${SortByToPathPrefix[defaultSortValue]}/category/${e.target.value}/1`
                )
          }
          variant="selectWhite"
        />
      </div>
    </Grid>
  )
}
ArticlesSortAndFilterControls.propTypes = {
  pagePathPrefix: PropTypes.string,
  topCategories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
}
