/** @jsx jsx */

import { Fragment } from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import theme from "../gatsby-plugin-theme-ui"
import {
  TransformedArticlePropTypes,
  ArticlePropTypes,
  GatsbyImagePropType,
  PrismicPlainTextFieldPropType,
  CategoryPropTypes,
} from "../utils/propTypes"
import { transformArticlesData } from "../utils/articleUtils"
import { extractPagePathPrefix } from "../utils/paginationUtils"
import { extractBaseSeoFields } from "../utils/seoUtils"
import { Layout } from "../components/Layout"
import { Pagination } from "../components/Pagination"
import { DividerType } from "../hooks/divider"
import { Masthead } from "../components/Masthead"
import { MediaMatcher } from "../components/MediaMatcher"
import { ArticlesSortAndFilterControls } from "../components/articles/ArticlesSortAndFilterControls"
import { ArticleBriefCard } from "../components/ArticleBriefCard"
import { ItemsGrid } from "../components/ItemsGrid"

const Content = ({
  title,
  masthead_background,
  articles,
  topCategories,
  page,
  numPages,
  pagePathPrefix,
  isMobile,
}) => (
  <Fragment>
    <Masthead
      heading={title.text}
      headingStyles={{ color: "sandYellow" }}
      showDivider={isMobile}
      dividerType={DividerType.DEFAULT_YELLOW}
      image={masthead_background}
      otherChildren={
        <ArticlesSortAndFilterControls
          pagePathPrefix={pagePathPrefix}
          topCategories={topCategories}
        />
      }
      containerStyles={{ height: ["400px", "300px", "350px", "400px"] }}
    />
    <div sx={{ ...theme.basePageStyles.contentContainer }}>
      <ItemsGrid
        items={articles}
        component={ArticleBriefCard}
        emphasizeFirst={!isMobile}
        itemProps={{ displayBadges: true }}
      />
      <Pagination
        selected={page}
        numPages={numPages}
        pagePathPrefix={pagePathPrefix}
      />
    </div>
  </Fragment>
)
Content.propTypes = {
  title: PrismicPlainTextFieldPropType,
  masthead_background: GatsbyImagePropType,
  articles: PropTypes.arrayOf(PropTypes.shape(TransformedArticlePropTypes)),
  topCategories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
  page: PropTypes.number,
  numPages: PropTypes.number,
  pagePathPrefix: PropTypes.string,
  isMobile: PropTypes.bool,
}

export const ArticlesPageTemplate = ({ data, pageContext, location }) => {
  const { page, numPages, topCategories } = pageContext
  const pagePathPrefix = extractPagePathPrefix(location.pathname)
  const { title, masthead_background } = data.prismicArticlesPage.data
  const articles = transformArticlesData(data.allPrismicArticle)

  const props = {
    title,
    masthead_background,
    topCategories,
    articles,
    page,
    numPages,
    pagePathPrefix,
  }

  const seoProps = extractBaseSeoFields({
    ...data.prismicArticlesPage.data,
    type: data.prismicArticlesPage.type,
    page,
  })

  return (
    <Layout seoProps={seoProps}>
      <MediaMatcher.ServerRender predicted={"mobilePortrait"}>
        <MediaMatcher.Matcher
          mobilePortrait={<Content isMobile={true} {...props} />}
          tablet={<Content isMobile={false} {...props} />}
        />
      </MediaMatcher.ServerRender>
    </Layout>
  )
}

ArticlesPageTemplate.propTypes = {
  data: PropTypes.shape({
    allPrismicArticle: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(ArticlePropTypes),
        })
      ),
    }).isRequired,
    prismicArticlesPage: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.shape({
        title: PrismicPlainTextFieldPropType,
        masthead_background: PropTypes.shape({
          gatsbyImageData: GatsbyImagePropType,
        }),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    page: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    topCategories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes))
      .isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}
